.btn.focus,
.btn:focus,
.btn.active,
.btn:active,
.btn.hover,
.btn:hover {
    box-shadow: none;
    outline: none;
}

.btn-primary {
    display: inline-block;
    padding: 18px 30px;
    background: #2a85ff;
    color: #fff;
    position: relative;
    border: 0;
    outline-width: 0;
    overflow: hidden;
    user-select: none;
    font-family: "TSTAR Regular", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    -webkit-transition: background 200ms ease-in-out, -webkit-transform 150ms ease-in-out;
    transition: background 200ms ease-in-out, -webkit-transform 150ms ease-in-out;
    transition: background 200ms ease-in-out, transform 150ms ease-in-out;
    transition: background 200ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
    -webkit-transform: scale(1);
    transform: scale(1);
    cursor: pointer;
    border-radius: 0;

    &:hover,
    &:focus {
      background: #2a85ff;
    }
}

.btn__arrow-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.btn__arrow {
  position: relative;
  opacity: 0;
  -webkit-transition: opacity 350ms ease-in-out, -webkit-transform 350ms ease-in-out 100ms;
  transition: opacity 350ms ease-in-out, -webkit-transform 350ms ease-in-out 100ms;
  transition: transform 350ms ease-in-out 100ms, opacity 350ms ease-in-out;
  transition: transform 350ms ease-in-out 100ms, opacity 350ms ease-in-out, -webkit-transform 350ms ease-in-out 100ms;
  color: #fff;
}

.btn__text {
  display: block;
  position: relative;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
  transition: transform 150ms ease-in-out, opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  line-height: 0.5;
  margin-top: 0.2em
}

.btn__text .svg {
  margin-top: -0.2em;
}

.btn:hover .arrow,
.btn:focus .arrow {
  -webkit-animation: btnArrow 250ms ease-in-out forwards;
          animation: btnArrow 250ms ease-in-out forwards
}

.btn:hover .arrow,
.btn:focus .arrow {
  -webkit-animation: btnArrow 250ms ease-in-out forwards;
          animation: btnArrow 250ms ease-in-out forwards
}

.btn:hover .arrow svg path, .btn:focus .arrow svg path {
  -webkit-animation: dash 200ms ease-in-out both 150ms;
          animation: dash 200ms ease-in-out both 150ms;
}

.btn[data-js-btn-mouseoff="true"] .btn__arrow {
  -webkit-animation: btnArrowOut 250ms ease-in-out forwards;
          animation: btnArrowOut 250ms ease-in-out forwards;
}

.btn[data-js-btn-mouseoff="true"] .btn__text {
  opacity: 0;
}

.btn:hover .btn__text, .btn:focus .btn__text {
  opacity: 0;
  -webkit-transform: translate3d(5rem, 0, 0);
          transform: translate3d(5rem, 0, 0);
}

.btn:hover .arrow .arrow__middle {
  -webkit-animation: arrowMiddle 350ms ease-in-out forwards;
          animation: arrowMiddle 350ms ease-in-out forwards;
}

.arrow {
    width: 36px;
    height: 18px;
}

.arrow svg {
    position: absolute;
    right: 0;
    top: 0;
    width: 9px;
    height: 100%;
    color: currentColor;
}

.arrow__middle {
    position: absolute;
    top: 50%;
    right: 1px;
    width: 100%;
    margin-top: -1px;
    height: 2px;
    background: currentColor;
    border-radius: 2px;
    opacity: 1;
}

@keyframes btnArrow {
  0% {
    -webkit-transform: translate3d(-100px, 0, 0);
            transform: translate3d(-100px, 0, 0);
    width: 100px;
    opacity: 1;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    width: 36px;
    opacity: 1;
  }
}

@keyframes btnArrowOut {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    width: 36px;
    opacity: 1;
  }

  100% {
    -webkit-transform: translate3d(100px, 0, 0);
            transform: translate3d(100px, 0, 0);
    width: 72px;
    opacity: 0;
  }
}

@keyframes arrowMiddle {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
