body {
  @include font-size(18px);
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  font-family: $font-family-serif;
}

:root {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font-family;
}