@function calculate-rem($size) {
  $rem-size: $size / 16px;
  @return $rem-size * 1rem;
}

@mixin font-size($size) {
  font-size: calculate-rem($size);
}

@mixin line-height($size) {
  line-height: calculate-rem($size);
}
