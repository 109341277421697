html,
body {
    padding: 0;
    margin: 0;
}

body {
    background: #1C1C52;
}

img {
  display: block;
  max-width: 100%;
}


@include media-breakpoint-up(xl) {
  .col-marg-left {
    margin-left: 100px;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.row {
  flex-wrap: wrap;
}