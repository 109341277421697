.card {
  border: 0;
  padding: 60px 50px;
  min-height: 375px;

  &__body {
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    p {
      margin-bottom: 30px;
    }
  }

  &__footer,
  &__header {
    display: flex;
    justify-content: center;
  }

  &__header {
    margin-bottom: 30px;
  }
}