// Grid settings
$enable-flex: false;
$grid-columns: 12;

$black: #000;
$white: #fff;

$base-font-size: 12px;
$font-size: 16px;
$base-line-font-size: 16;

$color-primary: $black;
$color-secondary: $black;

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 768px,
  md: 880px,
  lg: 960px,
  xl: 1200px
);

@font-face {
  font-family: 'TSTAR Regular';
  src: url('../fonts/T-Star-Regular.eot');
  src:
    url('../fonts/T-Star-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/T-Star-Regular.woff2') format('woff2'),
    url('../fonts/T-Star-Regular.woff') format('woff');
}

@font-face {
  font-family: 'TSTAR Bold';
  src: url('../fonts/T-Star-Bold.eot');
  src:
    url('../fonts/T-Star-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/T-Star-Bold.woff2') format('woff2'),
    url('../fonts/T-Star-Bold.woff') format('woff');
}

$font-family-serif: 'TSTAR Regular', system-ui;
$headings-font-family: 'TSTAR Bold', system-ui;

$use-rem: true !global; // use rem as default unit?
$use-em: false !global; // use em as default unit?
$use-percent: false !global; // use percentage as default unit?
$use-px-fallback: true !global; // allow for pixel fall back