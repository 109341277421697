header.banner {
  color: $white;
  padding-top: 30px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  .brand {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    svg {
      width: 192px;
      height: 50px;
    }
  }

  a {
    color: $white;
    font-size: 20px;

    &.btn {
      margin-left: 30px;
    }
  }

  .cta {
    margin-top: 15px;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      text-align: right;
    }
  }
}