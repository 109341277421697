.footer {
  color: $white;
  padding-top: 50px;
  padding-bottom: 50px;

  .col {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }
}
